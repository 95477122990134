.header
	line-height 42px

	@media (max-width $screen-xs-max)
		text-align center

	&__logo
		display inline-block
		padding-right 20px
		margin-right 20px
		font-size 30px
		font-weight 300
		letter-spacing 1px
		color $gray-base
		border-right 1px solid $gray-base

		@media (max-width $screen-xs-max)
			display block
			margin-right 0
			padding-right 0
			border-right none

	&__title
		display inline-block
		font-size 15px
		font-weight 500
		margin 0
		padding 0
		vertical-align super

	&__phone
		font-size 20px
		font-weight 300
		color $gray-base

		@media (max-width $screen-xs-max)
			line-height 30px
			display block
			margin-left 15px
			margin-right 15px

		&:nth-child(1)
			float left

			@media (max-width $screen-xs-max)
				float none

		&:nth-child(2)
			float right

			@media (max-width $screen-xs-max)
				float none

	&__callback
		line-height 19px

		@media (max-width $screen-xs-max)
			margin-top 10px
