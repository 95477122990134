.modal
	position fixed
	width 280px
	padding 25px
	top 35%
	left 50%
	margin-left -140px
	background-color #fafafa
	box-shadow 0px 15px 12px 0px rgba(0,0,0,0.22),
		0px 19px 38px 0px rgba(0,0,0,0.30)
	border-radius 2px

	&__overlay
		position fixed
		top 0
		right 0
		bottom 0
		left 0
		background-color rgba(0, 0, 0, .2)
