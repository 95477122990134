.form

	&-relative
		position relative

	&__title
		margin-bottom 20px

	&__group
		margin-bottom 20px

	&__label
		display block
		margin-bottom 0
		font-weight 500
		font-size 14px

	&__input
		width 100%
		padding 0 15px
		line-height 46px
		border 0
		border-radius 3px
		background-color rgba(0, 0, 0, 0.05)

	&__legend
		font-size 14px
		opacity .5

	&__loading
		display none
		position absolute
		top 0
		right 0
		bottom 0
		left 0
		padding-top 35%
		background-color rgba(255, 255, 255, .75)
		text-align center

	&-callback

		.form__label
			font-size 12px
			line-height 22px
			opacity .5

		.form__input
			background-color transparent
			padding 0
			font-size 24px
			border-bottom 2px solid transparent
			border-radius 0

			&:focus
				outline none
				border-bottom 2px solid $brand-primary
