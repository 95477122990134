.btn
	display inline-block
	padding 8px 12px
	font-weight 500
	font-size 14px
	text-transform uppercase
	border 0
	background-color transparent
	transition all .2s ease

	&-outline
		border-width 1px
		border-style solid
		border-radius 3px

		&:hover
			background-color $brand-primary
			color #fff

	&-fill
		background-color $brand-primary
		color #fff
		border-radius 3px

	&-shadow
		box-shadow 0px 1px 4px 0px rgba(0, 0, 0, 0.2)

		&:hover
			box-shadow 0px 1px 4px 0px rgba(0, 0, 0, 0.5)

	&-primary
		border-color $brand-primary
		color $brand-primary

	&-gray
		color #999
