.bid

	&__compressor
		margin-top 150px
		text-align center

		img
			max-width 100%
			height auto

	&__form
		margin-top 110px
