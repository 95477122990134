.footer
	padding-top 40px
	padding-bottom 40px
	background-color #ececec

	@media (max-width $screen-xs-max)
		text-align center

	&__logo
		display inline-block
		padding-right 20px
		margin-right 20px
		font-size 30px
		font-weight 300
		letter-spacing 1px
		color $gray-base
		border-right 1px solid $gray-base

		@media (max-width $screen-xs-max)
			display block
			margin-right 0
			padding-right 0
			border-right none

	&__title
		display inline-block
		font-size 15px
		font-weight 500
		margin 0
		padding 0
		vertical-align super

	&__phone
		font-size 20px
		font-weight 300
		color $gray-base

	&__address
		margin-top 10px

	&__copyright
		display block
		margin-top 30px
		opacity .4
		color $gray-base
		transition all .2s ease

		&:hover
			opacity 1

		span
			text-decoration underline

	&__contacts
		text-align right

		@media (max-width $screen-xs-max)
			margin-top 40px
			text-align center
