.price
	&__table
		margin-top 60px

	&__row
		height 48px
		border-bottom 1px solid $gray-base

		@media (max-width $screen-xs-max)
			height auto
			padding 15px

		&:after
			display table
			clear both

		&:nth-last-child(1)
			border-bottom 0

		& > div
			height 48px
			padding 15px
			float left

			@media (max-width $screen-xs-max)
				float none
				height auto
				padding 0

		& > div:nth-child(1)
			width 70%
			border-right 1px solid $gray-base

			@media (max-width $screen-xs-max)
				width 100%
				border-right 0

		& > div:nth-child(2)
			width 30%

			@media (max-width $screen-xs-max)
				width 100%


	&__head
		font-weight 500

		@media (max-width $screen-xs-max)
			display none
