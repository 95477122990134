.section
	padding-top 40px
	padding-bottom 40px

	&-bid
		min-height 700px
		padding-top 40px
		padding-bottom 40px
		background rgba(236,236,236,0.20)
		background-image linear-gradient(-180deg, rgba(255,255,255,0.10) 0%, rgba(0,0,0,0.10) 100%)

	&-price
		position relative
		background-image url('/static/images/price/price__bg.jpg')
		background-position bottom
		background-repeat no-repeat
		background-size 100% auto
		background-color #ececec

		&:before
			content ''
			position absolute
			width 81px
			height 185px
			top -172px
			left 5%
			background-image url('/static/images/price/price__drill.png')
			z-index -1

	&-content
		position relative
		min-height 700px
		padding-top 90px
		padding-bottom 90px
		background-image url('/static/images/content/content__bg.jpg')
		background-position right
		background-size auto 100%
		background-repeat no-repeat
		line-height 25px

		@media (max-width $screen-xs-max)
			background-image none
			background-color #f4f4f4
			padding-top 30px
			padding-bottom 30px

		&:before
			content ''
			position absolute
			top 0
			right 0
			bottom 0
			left 0
			background-image linear-gradient(270deg, rgba(255,255,255,0.00) 26%, rgba(255,255,255,1) 50%, #FFFFFF 100%)

			@media (max-width $screen-md-max)
				background-image linear-gradient(270deg, rgba(255,255,255,0.00) 26%, rgba(255,255,255,1) 80%, #FFFFFF 100%)

